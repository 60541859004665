<template lang="pug">
  .account-list
    AppOverlayLoader(:state="loading")
    AccountList(
      :accounts="items"
      :action-title="'actions.send_data_to_ota'"
      :progress-bar-label="$t('components.app_progressbar.sending_data')"
      :progress-data="exportsProgress"
      @action-click="sendDataToOta"
    )
    .start-export-hint {{ $t("ota_accounts.send_data_to_ota_button_hint") }}
</template>

<script>
  // store modules
  import otaAccountsModule from "@/config/store/company_system/ota_accounts"

  // mixins
  import withWebSocket from "@/mixins/withWebSocket"
  import withStoreModule from "@/mixins/withStoreModule"

  const otaAccountsStoreMixin = withStoreModule(otaAccountsModule, {
    name: "companiesOtaAccounts",
    readers: ["loading", "items", "exportsProgress"],
    mutations: { setExportsProgress: "SET_EXPORTS_PROGRESS" },
    actions: {
      fetchOtaAccounts: "FETCH_ITEMS",
      sendDataToOtaAction: "SEND_DATA_TO_OTA"
    }
  })

  export default {
    components: {
      AccountList: () => import("@/components/CompanySystem/AccountList"),
      AppOverlayLoader: () => import("@/components/elements/AppOverlayLoader")
    },

    mixins: [otaAccountsStoreMixin, withWebSocket],

    mounted() {
      this.fetchOtaAccounts()

      this.webSocketSubscribe("TrackingCompaniesExportChannel", { received: this.setExportsProgress })
    },

    methods: {
      sendDataToOta({ id }) {
        this.sendDataToOtaAction(id)
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"

  .account-list
    overflow: auto
    margin-top: 15px

    .start-export-hint
      color: $default-gray
      float: right
      font-size: 0.8em
      margin-top: 15px
</style>
