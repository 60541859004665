import StoreItemsModule from "@/config/store/StoreItemsModule"
import { withLoading } from "@/config/store/StoreItemsModule/helpers"
import extractMutations from "./mutations"
import extractActions from "./actions"

const baseURI = "/companies/ota_accounts"

const store = new StoreItemsModule({
  baseURI: baseURI,
  presetActions: ["fetch"]
})

store.mergeState({
  exportsProgress: {}
})

store.mergeMutations(extractMutations())
store.mergeActions(extractActions({ baseURI }), withLoading)

export default store
