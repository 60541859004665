import { api } from "@/config"
import { handleFailedResponse } from "@/helpers/common"

export default ({ baseURI }) => ({
  SEND_DATA_TO_OTA: async ({ commit }, accountId) => {
    try {
      const { data } = await api.post(`${baseURI}/${accountId}/send_data_to_ota`)
      commit("SET_ITEMS", data.data)
    } catch (e) {
      handleFailedResponse(e)
      throw e
    }
  }
})
